/* eslint-disable @typescript-eslint/no-explicit-any */
import {createElement, FC, StyleHTMLAttributes, useContext, useEffect, useLayoutEffect, useMemo, useRef} from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {SingleSpaContext} from 'single-spa-react';

interface ParcelProps {
  /**
   * Single spa config
   */
  config:
    | (() => Promise<any>)
    | {
        bootstrap(): Promise<any>;
        mount(): Promise<any>;
        unmount(): Promise<any>;
        update(): Promise<any>;
        name: string;
      };

  /**
   * Fired when parcel is mounted
   */
  didMount?: () => void;

  /**
   * HTML element tag of wrapping element
   */
  wrapWith?: string;

  /**
   * ClassName of wrapping element
   */
  className?: string;

  /**
   * Style of wrapping element
   */
  wrapStyle?: StyleHTMLAttributes<unknown>;

  /**
   * Any parcel props
   */
  [x: string]: any;
}

/**
 * Sanitize props
 */
function getParcelProps(props: ParcelProps) {
  const parcelProps: Partial<ParcelProps> = {...props};

  delete parcelProps.mountParcel;
  delete parcelProps.config;
  delete parcelProps.wrapWith;
  delete parcelProps.appendTo;
  delete parcelProps.handleError;
  delete parcelProps.parcelDidMount;

  return parcelProps;
}

interface SpaContext {
  mountParcel: (config: ParcelProps['config'], options: any) => any;
}

export const Parcel: FC<ParcelProps> = (props) => {
  const {config, wrapWith = 'div', wrapStyle, className, children, didMount} = props;
  const context = useContext<SpaContext>(SingleSpaContext);
  const nodeRef = useRef<HTMLElement | null>(null);
  const mountedRef = useMountedRef();
  const parcelRef = useRef<any>(null);
  const addThingToDo = useMemo(() => {
    let chain = Promise.resolve();
    let hasError = false;
    return (action: string, thing: () => void) => {
      if (hasError && action !== 'unmount') {
        // In an error state, we don't do anything anymore except for unmounting
        return;
      }
      chain = chain
        .then(() => {
          const mounted = mountedRef.current;
          if (!mounted && action !== 'unmount') {
            // Never do anything once the react component unmounts
            return;
          }

          return thing();
          // if (action === 'unmount') {
          //   return thing();
          // }
          // // Never do anything once the react component unmounts
          // if (mountedRef.current) {
          //   return thing();
          // }
        })
        .catch((err) => {
          chain = Promise.resolve(); // reset so we don't .then() the bad promise again
          hasError = true;

          if (err && err.message) {
            err.message = `During '${action}', parcel threw an error: ${err.message}`;
          }

          // No more things to do should be done -- the parcel is in an error state
          throw err;
        });
    };
  }, [mountedRef]);
  useEffect(() => {
    if (nodeRef.current === null) {
      return undefined;
    }
    addThingToDo('mount', () => {
      const parcel = context.mountParcel(config, {domElement: nodeRef.current, ...getParcelProps(props)});
      parcelRef.current = parcel;
      return parcel.mountPromise;
    });
    if (typeof didMount === 'function') {
      addThingToDo('emitMounted', async () => {
        try {
          didMount();
        } catch (e) {
          console.error(e);
        }
      });
    }
    return () => {
      addThingToDo('unmount', () => {
        const parcel = parcelRef.current;
        if (parcel && parcel.getStatus() === 'MOUNTED') {
          return parcel.unmount();
        }
      });
    };
    // exec only at one time
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    addThingToDo('update', () => {
      const parcel = parcelRef.current;
      if (parcel && parcel.update) {
        return parcel.update(getParcelProps(props));
      }
    });
  });
  return createElement(wrapWith, {ref: nodeRef, className, style: wrapStyle}, children);
};

function useMountedRef() {
  const mountedRef = useRef(false);
  useLayoutEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, []);
  return mountedRef;
}
