import {createElement, StrictMode, FC, useMemo, Fragment} from 'react';
import {Router} from 'react-router-dom';
import {history} from '@netvision/lib-history';
import createRenderer from './Renderer';
import * as RenderModel from './RenderModel';

interface Props {
  widgetTree: RenderModel.Node | RenderModel.Node[];
  decorators: RenderModel.Decorator[];
}

export const Root: FC<Props> = ({widgetTree, decorators = []}) => {
  const renderChildren = useMemo(() => createRenderer(decorators), [decorators]);
  let el = createElement(Fragment, undefined, 'Widget tree is not provided');
  if (widgetTree) {
    el = createElement(Fragment, undefined, renderChildren(widgetTree instanceof Array ? widgetTree : [widgetTree]));
  }
  return createElement(StrictMode, undefined, createElement(Router, {history}, el));
};
