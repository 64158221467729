import './set-public-path';
import React, {createElement} from 'react';
import ReactDOM from 'react-dom';
import {Root} from './Root';
import singleSpaReact from 'single-spa-react';
import {Node, Widget} from './RenderModel';

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,
  errorBoundary(err, info, props) {
    // https://reactjs.org/docs/error-boundaries.html
    console.error(err, info, props);
    return createElement('div', undefined, 'Fatal error');
  }
});

export const bootstrap = lifecycles.bootstrap;
export const mount = lifecycles.mount;
export const unmount = lifecycles.unmount;
export const update = lifecycles.update;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface WidgetReceivedProps<P extends {[k: string]: any}, A extends string> extends Widget<P, A> {
  mountChildren(domElement: HTMLElement, areaNameOrChildren: string | Node[]): () => void;
}

export * from './RenderModel';
