import {
  Children,
  createElement,
  Fragment,
  ReactPortal,
  useMemo,
  useLayoutEffect,
  useRef,
  useState,
  ReactNode
} from 'react';
import {Link, Redirect, Route, Switch} from 'react-router-dom';
import * as RenderModel from './RenderModel';
import {createPortal} from 'react-dom';
import {Parcel} from './Parcel';

function buildGridElement({
  renderChildren,
  columns = ['auto'],
  rows = ['auto'],
  areas = [],
  style = {}
}: RenderModel.Grid & RenderModel.Renderer) {
  return createElement(
    'section',
    {
      style: {...style, ...computeGridStyle(columns, rows)}
    },
    Children.toArray(
      areas.map((area) =>
        buildRoute(area, (area) => {
          const {start = [1, 1], span = [1, 1], style = {}, children} = area;
          if (area.visible === false) {
            return null;
          }
          return createElement('div', {style: {...style, ...computeAreaStyle(start, span)}}, renderChildren(children));
        })
      )
    )
  );
}

function computeGridStyle(columns: Array<unknown>, rows: Array<unknown>) {
  return {
    display: 'grid',
    gridTemplateColumns: columns.join(' '),
    gridTemplateRows: rows.join(' ')
  };
}

function computeAreaStyle(start: number[], span: number[]) {
  return {
    display: 'block',
    gridColumnStart: start[0],
    gridColumnEnd: `span ${span[0]}`,
    gridRowStart: start[1],
    gridRowEnd: `span ${span[1]}`
  };
}

function WidgetElement({src, renderChildren, props, areas, onMount}: RenderModel.Widget & RenderModel.Renderer) {
  const [portals, setPortals] = useState<Array<ReactPortal>>([]);
  const isMountedRef = useRef(false);
  useLayoutEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);
  const mount = useMemo(() => {
    let nextId = 0;
    return (domElement: HTMLElement, areaNameOrChildren: string | RenderModel.Node[]) => {
      if (isMountedRef.current && domElement) {
        let children = null;
        if (typeof areaNameOrChildren === 'string') {
          if (areas) {
            const area = areas.find((area) => area.name === areaNameOrChildren);
            if (area) {
              children = area.children;
            }
          }
        } else if (areaNameOrChildren instanceof Array && areaNameOrChildren.length > 0) {
          children = areaNameOrChildren;
        }
        if (children) {
          const portal: ReactPortal = createPortal(renderChildren(children), domElement, (nextId++).toString());
          setPortals((prev) => [...prev, portal]);
          return () => {
            if (isMountedRef.current) {
              setPortals((prev) => {
                const set = new Set(prev);
                set.delete(portal);
                return Array.from(set);
              });
            }
          };
        }
      }
      // if parcel could not be mounted, return dummy unmount callback
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      return () => {};
    };
  }, [renderChildren, areas]);
  const [updatedProps, setUpdatedProps] = useState(props);
  const didMount =
    typeof onMount === 'function'
      ? () => {
          onMount(setUpdatedProps);
        }
      : undefined;
  return createElement(
    Parcel,
    {
      props: updatedProps,
      areas,
      mountChildren: mount,
      didMount,
      config: () => System.import(src),
      className: 'single-spa-parcel-container'
    },
    portals
  );
}

function buildDOMElement({tag, renderChildren, props, children = []}: RenderModel.DOMElement & RenderModel.Renderer) {
  return createElement(tag, props, renderChildren(children));
}

function buildLink({renderChildren, to, props, children}: RenderModel.Link & RenderModel.Renderer) {
  return createElement(
    Link,
    {
      to,
      ...(props as Record<string, unknown>)
    },
    renderChildren(children)
  );
}

function buildRoute<T extends RenderModel.Routable>(props: T, renderNext: (props: T) => ReactNode) {
  const {path} = props;
  const render = () => renderNext(props);
  if (path) {
    return createElement(Route, {
      path,
      render
    });
  }
  return render();
}

function buildText(value: unknown) {
  if (typeof value === 'string') {
    return createElement(Fragment, {}, value);
  }
  console.error('text`s value should be a string');
  return null;
}

function buildChild(props: RenderModel.Node & RenderModel.Renderer) {
  switch (props.type) {
    case 'link':
      return buildLink(props);
    case 'text':
      return buildText(props.value);
    case 'html':
      return buildDOMElement(props);
    case 'grid':
      return buildGridElement(props);
    case 'widget':
      return createElement(WidgetElement, props);
    default:
      console.error('unsupported child type');
      return null;
  }
}

function buildSwitch({renderChildren, children}: RenderModel.Switch & RenderModel.Renderer) {
  return createElement(Switch, undefined, renderChildren(children));
}

function buildRedirect({to, from}: RenderModel.Redirect) {
  return createElement(Redirect, {
    to,
    from
  });
}

function buildChildElement(props: RenderModel.Node & RenderModel.Renderer) {
  if (props.visible === false) {
    return null;
  }
  switch (props.type) {
    case 'switch':
      return buildSwitch(props);
    case 'redirect':
      return buildRedirect(props);
    default:
      return buildRoute(props, buildChild);
  }
}

function createRenderer(decorators: Array<RenderModel.Decorator>) {
  return function renderChildren(children: RenderModel.Node[]): ReactNode[] {
    return Children.toArray(
      children.map((child) => {
        decorators.forEach(({type, decorate}) => {
          if (child.type === type) {
            decorate(child);
          }
        });
        return buildChildElement({
          ...child,
          renderChildren
        });
      })
    );
  };
}

export default createRenderer;
